import React from "react";
import styled from "styled-components";
import { Route, Switch, Redirect } from "react-router-dom";
import { Start } from "./1. start/Start.js";
import { OFirmieBP } from "./3. o_Firmie/OFirmie_BP.js";
import { OFirmieWB } from "./3. o_Firmie/OFirmie_WB.js";
import { Realizacje } from "./4. realizacje/Realizacje.js";
import { Galeria } from "./4. realizacje/Galeria.js";
import { KontaktWB } from "./5. kontakt/Kontakt_WB.js";
import { KontaktBP } from "./5. kontakt/Kontakt_BP.js";
import { useRouteScroll } from "../hooks/useRouteScroll.js";

export const Main = () => {
  useRouteScroll();
  return (
    <Content>
      <Switch>
        <Route path="/" exact component={Start} />
        <Route path="/wykonawstwo_budowlane" exact component={OFirmieWB} />
        <Route path="/biuro_projektow" exact component={OFirmieBP} />
        <Route
          path="/wykonawstwo_budowlane/realizacje"
          exact
          component={Realizacje}
        />
        <Route
          path="/wykonawstwo_budowlane/kontakt"
          exact
          component={KontaktWB}
        />
        <Route path="/biuro_projektow/kontakt" exact component={KontaktBP} />
        <Route
          path="/wykonawstwo_budowlane/realizacje/:directory"
          exact
          component={Galeria}
        />
        <Redirect to="/" />
      </Switch>
    </Content>
  );
};

const Content = styled.div`
  left: 0;
  background: #e6ebeb;
  display: flex;
  min-height: 100vh;
  width: 100%;
  z-index: 0;
  opacity: 0.9;
`;
