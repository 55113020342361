import React from "react";
import styled from "styled-components";
import obrazekTWB from "../../images/backgrounds/constr2.jpg";

export const OFirmieWB = () => (
  <Wraper>
    <Obrazek src={obrazekTWB} />
    <Text>
      <P1>
        <h4>
          Przedsiębiorstwo Wielobranżowe Usług-Handel Zieliński rozpoczęło
          działalność w 1991 roku. Od 2009 roku właścicielem firmy jest Rafał
          Zieliński. Wieloletnie doświadczenie oraz pełne zaangażowanie sprzyja,
          podejmowaniu się wszelkich, nawet bardzo skomplikowanych, wyzwań.
          Jesteśmy firmą rodzinną, która zatrudnia wysokiej klasy fachowców,
          którzy poza uprawnieniami budowlanymi, posiadają poświadczenia
          bezpieczeństwa upoważniające ich do dostępu do informacji niejawnych.
          <br />
        </h4>
      </P1>
      <P1>
        <h4>
          Wychodząc na przeciw wymaganiom rynku, w 2016 roku, utworzyliśmy Biuro
          Projektów wraz z Kancelarią Niejawną. Dzięki temu świadczymy
          kompleksową obsługę inwestycji, od projektu do realizacji. Zatrudniamy
          projektantów i specjalistów min. branż: architektonicznej,
          konstrukcyjno-budowlanej, sanitarnej, elektrycznej, teletechnicznej,
          teleinformatycznej, telekomunikacyjnej, energetycznej, drogowej,
          geodezyjnej i geotechnicznej. W skład naszego zespołu wchodzą także
          wyspecjalizowani kosztorysanci oraz uprawnieni pracownicy Pionu
          Ochrony Informacji Niejawnych.
          <br />
        </h4>
      </P1>
      <P1>
        <h4>
          Naszymi klientami są głównie podmioty resortów i administracji
          Państwowej, min. Ministerstwo Zdrowia, Ministerstwo Obrony Narodowej,
          Ministerstwo Spraw Wewnętrznych i Administracji.
          <br />
        </h4>
      </P1>
      <P1>
        <h3>
          W zakresie świadczonych usług oferujemy:
          <br />
        </h3>
        <h4>
          • Przygotowanie dokumentacji przetargowej
          <br />
          • Ocenę przydatności terenów dla potrzeb inwestycji
          <br />
          • Koncepcje zabudowy
          <br />
          • Projekty budowlane i wykonawcze
          <br />
          • Uzyskiwanie decyzji administracyjnych i uzgodnień
          <br />
          • Nadzór autorski oraz inwestorski
          <br />
        </h4>
      </P1>
      <P1>
        <h3>
          Posiadamy następujące uprawnienia:
          <br />
        </h3>
        <h4>
          Koncesja MSWiA;
          <br />
          Pion Ochrony Informacji Niejawnej;
          <br />
          Kancelarię Niejawną;
          <br />
          Akredytowany system teleinformatyczny;
          <br />
          Licencje Pracownika Zabezpieczenia Technicznego II stopnia;
          <br />
          Poświadczenia Bezpieczeństwa Osobowego i Zaświadczenia o przeszkoleniu
          w zakresie OIN;
          <br />
          Uprawnienia projektowe branż: architektonicznej,
          konstrukcyjno-budowlanej, sanitarnej, elektrycznej, energetycznej,
          drogowej, geodezyjnej i geotechnicznej;
          <br />
          Uprawnienia projektowe Systemów Zabezpieczeń Technicznych klas
          SA-1/SA-4 , Stopnia 1-4 w zakresie Systemów Sygnalizacji Włamania i
          Napadu, Telewizji Dozorowej, Kontroli Dostępu;
          <br />
          Uprawnienia projektowe Systemów Wykrywania i Sygnalizacji Pożaru;
          <br />
          Uprawnienia SEP w zarkesie dozoru i eksploatacji urządzeń i instalacji
          elektrycznych.
          <br />
        </h4>
      </P1>
    </Text>
  </Wraper>
);

const Obrazek = styled.img`
  width: 100%;
  height: 20vh;
  position: relative;
  object-fit: cover;
  opacity: 60%;
  top: 0px;
  @media (max-width: 900px) {
    height: 330px;
  }
`;

const Wraper = styled.div`
  width: 100%;
  color: #003643ff;
`;

const Text = styled.div`
  width: 80vw;
  margin: auto;
  align-self: center;
  text-align: justify;
  text-justify: inter-word;
  padding-top: 40px;
  padding-bottom: auto;
`;

const P1 = styled.div`
  padding-bottom: 15px;
`;

// const Galery = styled.div``;
