import React from "react";
import styled, { css } from "styled-components";
import logoC from "../images/partnerzy/LOGO_C2022.jpg";

export const FooterCell1 = () => (
  <FooterCell>
    <h5>
      PWUH Rafał Zieliński&nbsp;-&nbsp;
      <a href="www.zielinski.info.pl">www.zielinski.info.pl</a>
    </h5>
    <p>ul. Moniuszki 35, 87-140 Chełmża</p>
    <p>NIP 879-231-22-44; REGON 340505780</p>
    <p>PKO BP o/Chełmża 35 1020 5011 0000 9002 0165 8756</p>
    <p>
      <a href="mailto:szczecin@zielinski.info.pl">szczecin@zielinski.info.pl</a>
    </p>
  </FooterCell>
);

export const FooterCell2 = () => (
  <FooterCell>
    <h5>Oddział Szczecin - Pracownia Projektowa</h5>
    <p>ul. Panoramiczna 13/6, 71-447 Szczecin</p>
    <p>
      tel.: &nbsp;<a href="tel:+48 501 120 563">501 120 563, &nbsp;</a>
      <a href="tel:+48 664 449 909">664 449 909</a>
    </p>
    <p>
      fax: &nbsp;<a href="tel:+48 91 433 33 38">91 433 33 38</a>
    </p>
    <p>
      <a href="mailto:biuro-projekt@zielinski.info.pl">
        biuro-projekt@zielinski.info.pl
      </a>
    </p>
  </FooterCell>
);

export const FooterCell3 = () => (
  <FooterCell>
    <h5>Oddział Szczecin – Kancelaria Niejawna</h5>
    <p>ul. Poznańska 1A/25,</p>
    <p>71-785 Szczecin</p>
  </FooterCell>
);

export const FooterCell4 = () => (
  <FooterCell>
    <h5>Oddział Szczecin - Realizacja Inwestycji Budowlanych</h5>
    <p>ul. Poznańska 1A/25, 71-785 Szczecin</p>
    <p>
      tel.: &nbsp;<a href="tel:91 421 39 42">91 421 39 42</a>
    </p>
    <p>
      fax: &nbsp;<a href="tel:91 421 39 42">91 421 39 42</a>
    </p>
    <p>
      <a href="mailto:szczecin@zielinski.info.pl">szczecin@zielinski.info.pl</a>
    </p>
  </FooterCell>
);

const FooterCell = styled.div`
  flex: 1 0;
  p {
  }
  h5 {
    margin-bottom: 25px;
    // box-shadow: 0px -1px 0px rgba(19, 17, 17, 0.4);
  }
  padding-left: 10px;
  padding-right: 10px;
  top: 0px;
  align-content: center;
`;

export const FooterTable = styled.table`
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  bottom: 0;
  left: 0;
  position: relative;
  display: flex;
  height: auto;
  padding-left: 90px;
  box-shadow: ${props =>
    props.dark
      ? "0px 1px 1px rgba(19, 17, 17, 0.4) inset"
      : "0px 1px 1px rgba(19, 17, 17, 0.4) inset"};

  /* UKRYWANIE FOOTERA NA HOVER */
  /* ${props => (props.hover ? "&" : ":hover")} {
    height: auto;
    background: ${props => (props.dark ? "#003643ff" : "#e6ebeb")};
    p {
      top: 0px;
      display: flex;
    }
  } */

  a {
    color: ${props => (props.dark ? "#e6ebeb" : "#003643ff")};
  }

  background: ${props => (props.dark ? "#003643ff" : "#e6ebeb")};
  opacity: 1;
  color: ${props => (props.dark ? "#e6ebeb" : "#003643ff")};
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Copy = () => (
  <Cstyle>
    <CR>
      <u>
        P.W.U.H. Zieliński, wszelkie prawa zastrzeżone
        <i>©</i>
        2022 www.zielinski.info.pl
      </u>
    </CR>
    <CR2>
      <h5>Kontakt:</h5>
    </CR2>
    <CR3 src={logoC}/>

  </Cstyle>
);

const CR3 = styled.img`
height: 80px;
width: auto;
right: 5px;
bottom: 25px;
position: absolute;
display: flex;
z-index: 2;
object-fit: contain;
@media (max-width: 1200px) {
  height: 60px;
  right: 2vw;
`;


export const FooterWraper = styled.div`
  position: relative;
  bottom: 0;
  z-index: 0;
  opacity: 1;
`;

const CR = styled.div`
  right: 5px;
  bottom: 5px;
  margin-top: auto;
  position: absolute;
  display: flex;
  z-index: 5;
  u {
    text-height: 12px;
  }
`;

const CR2 = styled.div`
  left: 15px;
  top: 0px;
  position: absolute;
  /* display: flex; */
  h5 {
    shadow: 0px 1px 1px gray;
    opacity: 60%;
  }
`;

const Cstyle = styled.div`
  i {
    text-height: 14px;
  }
`;


