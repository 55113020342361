import React from "react";
import styled from "styled-components";
import { MapU } from "./Map";
import obrazekTBP from "../../images/backgrounds/whiteabs3.jpg";

export const KontaktBP = () => (
  <Kontakt>
    <Obrazek src={obrazekTBP} />
    <div>
      <Osoba>
        <h2>Oddział Szczecin - Pracownia Projektowa</h2>
        <h4>ul. Panoramiczna 13/6, 71-447 Szczecin</h4>
        <h4>
          tel: &nbsp;<a href="tel:+48 511 414 105">664 449 909</a>
        </h4>
        <h4>
          fax: &nbsp;<a href="tel:+48 91 433 33 38">91 433 33 38</a>
        </h4>
        <h4>
          e-mail: &nbsp;
          <a href="mailto:projekt@zielinski.info.pl">
            projekt@zielinski.info.pl
          </a>
        </h4>
      </Osoba>
      <Osoba>
        <h2>Kierownik Biura - Piotr Zanoza</h2>
        <h4>
          tel: &nbsp;<a href="tel:+48 501 120 563">501 120 563</a>
        </h4>
        <h4>
          e-mail: &nbsp;
          <a href="mailto:p.zanoza@zielinski.info.pl">
            p.zanoza@zielinski.info.pl
          </a>
        </h4>
      </Osoba>
      <Osoba>
        <h2>Kierownik Administracji - Wioleta Socha</h2>
        <h4>
          tel: &nbsp;<a href="tel:+48 664 449 909">664 449 909</a>
        </h4>
        <h4>
          e-mail: &nbsp;
          <a href="mailto:biuro-projekt@zielinski.info.pl">
            biuro-projekt@zielinski.info.pl
          </a>
        </h4>
      </Osoba>
    </div>
    <MapU />
    <MapLink>
      <a href="https://www.openstreetmap.org/#map=15/53.4654/14.5429&amp;layers=N">
        <h4>Wyświetl większą mapę</h4>
      </a>
    </MapLink>
  </Kontakt>
);

const MapLink = styled.div`
  padding-left: 60px;
  text-align: center;
  h4,
  a {
    color: rgb(0, 54, 67);
    opacity: 0.95;
    text-decoration: none;
  }
  background: #e6ebeb;
  z-index: 3;
  box-shadow: 0px 1px 1px rgba(19, 17, 17, 0.4) inset;
`;

const Kontakt = styled.table`
  height: auto;
  padding-top: 90px
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  opacity: 0.9;
  justify-content: space-between;
  z-index: 0;
  @media (max-width: 900px) {
    padding-top: 240px;
  }
`;
const Osoba = styled.div`
  padding-top: 25px;
  padding-left: 180px;  
  a {
    color: #003643ff;
    opacity: 0.95;
  }
  h2 {
    color: rgb(0, 54, 67);
    opacity: 0.95;
  }
  h4 {
    color: rgb(0, 54, 67);
    opacity: 0.95;
  }
  @media (max-width: 900px) {
    padding-left: 20;
    padding-right: 20;
    text-algin: center;
  }
`;

const Obrazek = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  opacity: 60%;
  top: 0px;

`;
