import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { Footer } from "./Footer";
import { Main } from "./Main";
import { Top } from "./Top";

export const App = () => (
  <BrowserRouter>
    <GlobalStyle />
    <Top />
    <Main />
    <Footer />
  </BrowserRouter>
);

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

  body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    min-height: 100vh;
    width: 100%;
    background-image: url=("/images/backgrounds/DSC00706.jpg";);
  }

  h1 {
    font-size: 24px;
    font-weight: lighter;
    line-height: 1.6em;
    opacity: .95;
    font-family: 'Lato', sans-serif;
    @media (max-width: 1200px) {
      font-size: 18px;
    }
  }

  h2 {
    font-size: 20px;
    font-weight: lighter;
    line-height: .8em;
    opacity: .95;
    font-family: 'Lato', sans-serif;
    @media (max-width: 1200px) {
      font-size: 14px;
    }
  }

  h3 {
    font-size: 22px;
    font-weight: normal;
    line-height: .8em;
    opacity: .95;
    font-family: 'Lato', sans-serif;
    @media (max-width: 1200px) {
      font-size: 16px;
    }
  }

  h4 {
    font-size: 18px;
    font-weight: lighter;
    line-height: 1.2em;
    opacity: .95;
    font-family: 'Lato', sans-serif;
    @media (max-width: 1200px) {
      font-size: 12px;
    }
  }

  h5 {
    font-size: 14px;
    font-weight: lighter;
    line-height: 1.0em;
    opacity: .95;
    font-family: 'Lato', sans-serif;
    @media (max-width: 1200px) {
      font-size: 10px;
    }
  }

p {
    font-size: 14px;
    font-weight: lighter;
    line-height: .8em;
    opacity: .95;
    font-family: 'Lato', sans-serif;
    @media (max-width: 1200px) {
      font-size: 10px;
    }
  }

u {
    font-size: 11px;
    font-weight: lighter;
    text-decoration: none;
    line-height: .6em;
    opacity: .7;
    font-family: 'Lato', sans-serif;
    @media (max-width: 1200px) {
      font-size: 8px;
    }
  }
`;
