import React from "react";

import {
  FooterTable,
  FooterCell1,
  FooterCell2,
  FooterCell3,
  Copy,
  FooterWraper,
} from "./Footer_Common";

export const Footer_ContentBP = () => (
  <FooterWraper>
    <FooterTable dark={false}>
      <FooterCell1 />
      <FooterCell2 />
      <FooterCell3 />
      <Copy />
    </FooterTable>
  </FooterWraper>
);

