import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import styled, { css } from "styled-components";
import { Top_ContentBP } from "./Top_ContentBP";
import { Top_ContentWB } from "./Top_ContentWB";

export const Top = () => (
  <Header>
    <Switch>
      <Route path="/wykonawstwo_budowlane" component={Top_ContentWB} />
      <Route path="/biuro_projektow" component={Top_ContentBP} />
    </Switch>
  </Header>
);

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  
  @media (max-width: 900px) {
    flex-direction: column;
    position: absolute;
  }
`;
