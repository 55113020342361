import React from "react";
import styled from "styled-components";
import { Link, Route } from "react-router-dom";
import obrazekTBP from "../../images/backgrounds/constr4.jpg";

import realizacje from "../../images/realizacje/*/*.jpg";
console.log(Object.entries(realizacje));

export const Realizacje = () => (
  <Wraper>
    <Obrazek src={obrazekTBP} />
    {Object.entries(realizacje).map(([directory, files]) => (
      <GalleryLink to={`/wykonawstwo_budowlane/realizacje/${directory}`}>
        {Object.entries(files)
          .slice(0, 1)
          .map(([name, url]) => (
            <img src={url} alt={name} title={name} style={{ padding: 10 }} />
          ))}
        <Name>{directory}</Name>
      </GalleryLink>
    ))}
  </Wraper>
);

const Obrazek = styled.img`
  width: 100%;
  height: 20vh;
  position: relative;
  object-fit: cover;
  opacity: 60%;
  top: 0px;
  @media (max-width: 900px) {
    height: 330px;
  }
`;

const Name = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: white;
  font-size: 32px;
  text-shadow: 2px 2px rgba(30, 30, 30, 0.6);
  @media (max-width: 900px) {
    font-size: 16px;
  }
`;

const Wraper = styled.div`
  width: 100%;
  position: relative;
`;

const GalleryLink = styled(Link)`
  display: grid;
  object-fit: cover;
  top: 0px;
  margin: 20px;
  position: relative;
  text-decoration: none;
  :hover {
    opacity: 80%;
  }
  :active {
    opacity: 60%;
  }
  :focus-within {
    opacity: 60%;
  }

  img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 20vh;
    position: relative;
    object-fit: cover;
    opacity: 100%;
    box-shadow: 0px 0px 3px rgba(19, 17, 17, 0.4);
  }

  h2 {
    position: relative;
    width: auto;
    text-align: center;
    opacity: 0.95;
    color: rgb(0, 54, 67);
    box-shadow: 0px -1px 0px rgba(19, 17, 17, 0.4);
  }
`;
