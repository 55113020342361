import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import {Footer_ContentWB} from "./Footer_ContentWB";
import { Footer_ContentBP } from "./Footer_ContentBP";
import styled from "styled-components";

export const Footer = () => (

        <Switch>
            <Route
                path="/wykonawstwo_budowlane/kontakt"
                render={() => <Footer_ContentWB hover={true}/>}/>
            <Route
                path="/biuro_projektow/kontakt"
                render={() => <Footer_ContentBP hover={true}/>}/>
            <Route path="/wykonawstwo_budowlane" component={Footer_ContentWB}/>
            <Route path="/biuro_projektow" component={Footer_ContentBP}/>
        </Switch>

);
