import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logoBP from "../../images/logoBP.svg";
import logoWB from "../../images/logoWB.svg";
import bckgWB from "../../images/backgrounds/constr3.jpg";
import bckgBP from "../../images/backgrounds/drawing1.jpg";
import logoC from "../../images/partnerzy/LOGO_C2022.jpg";

export const Start = () => (
  <Intro>
    <Phome>
      <MenuLink to="/biuro_projektow">
        <LogoBig src={logoBP} />
      </MenuLink>
    </Phome>
    <BG1 src={bckgBP} />
    <Whome>
      <MenuLink to="/wykonawstwo_budowlane">
        <LogoBig src={logoWB} />
      </MenuLink>
    </Whome>
    <BG2 src={bckgWB} />
    <LC src={logoC}/>
  </Intro>
);

const LC = styled.img`

  height: 100px;
  width: auto;
  position: absolute;
  right: 30px;
  top: 50vh;
  transform: translate(0%, -50%);
  display: flex;
  z-index: 1;
  object-fit: contain;
  @media (max-width: 1100px) {
    width: 20%;
    height: auto;
    right: 2vw;
  }
`;

const BG1 = styled.img`
  width: 100vw;
  height: 50vh;
  position: fixed;
  bottom: 0px;
  display: flex;
  z-index: -1;
  object-fit: cover;
`;

const BG2 = styled.img`
  width: 100vw;
  height: 50vh;
  position: fixed;
  top: 0px;
  display: flex;
  z-index: -1;
  object-fit: cover;
`;

const LogoBig = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh;
  margin: 30px;
  @media (max-width: 900px) {
    height: auto;
    width: 70%;
  }
`;

const Intro = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
`;

const MenuLink = styled(Link)`
  position: fixed;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Phome = styled.div`
  width: 100vw;
  height: 50vh;
  position: fixed;
  bottom: 0px;
  display: flex;
  background: #f5f5f5ff;
  opacity: 70%;
  z-index: 0;
  :hover {
    background: #ebebebff;
  }
`;

const Whome = styled.div`
  width: 100vw;
  height: 50vh;
  position: fixed;
  top: 0px;
  display: flex;
  background: #004656ff;
  opacity: 70%;
  z-index: 0;
  :hover {
    background: #00566aff;
  }
`;
