import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import logoBP from "/images/logoBP.svg";
import logoWB from "/images/logoWB.svg";

export const Top_ContentBP = () => (
  <Menu>
    <MenuLink to="/" exact>
      <Logo src={logoBP} />
    </MenuLink>
    <MenuLink to="/biuro_projektow" exact>
      <h2>O firmie</h2>
    </MenuLink>
    <MenuLink to="/wykonawstwo_budowlane">
      <h2>Realizacja Inwestycji Budowlanych</h2>
    </MenuLink>
    <MenuLink to="/biuro_projektow/kontakt" exact>
      <h2>Kontakt</h2>
    </MenuLink>
  </Menu>
);

const Logo = styled.img`
  top: 0px;
  margin-bottom: 5px;
  margin-top: 5px;
  height: 80px;
  margin-left: 180px;
  margin-right: 80px;
  position: relative;
  @media (max-width: 948px) and (min-width: 900px) {
    margin-bottom: 25px;
    margin-top: 25px;
  }
  @media (max-width: 900px) {
    align: center;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Menu = styled.div`
  position: relative;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  box-shadow: 0px 1px 1px rgba(19, 17, 17, 0.4);
  background: #e6ebeb;
  opacity: 0.9;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const MenuLink = styled(NavLink)`
  background-image: #003643ff;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  display: flex;
  min-width: 60px;

  @media (max-width: 900px) {
    align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  h2 {
    width: auto;
    margin: auto;
    padding-right: 15px;
    padding-left: 15px;
    text-align: center;
    @media (max-width: 1200px) {
      padding-right: 5px;
      padding-left: 5px;
  }

  &.active {
    color: #003643ff;
    border-style: dotted;
    border-width: 2px;
    border-color: #d7dbdb;
    background-image: linear-gradient(to bottom, #cacfcf, #d7dbdb, #e6ebeb);
  }

  /* :focus-within {
    color: #003643ff;
    border-style: dotted;
    border-width: 2px;
    border-color: #afb3b3;
    background-image: linear-gradient(to bottom, #e6ebeb, #dee3e3, #d7dbdb);
  } */
`;
