import React from "react";

import {
  FooterTable,
  FooterCell1,
  FooterCell4,
  FooterCell3,
  Copy,
  FooterWraper
} from "./Footer_Common";

export const Footer_ContentWB = () => (
  <FooterWraper>
    <FooterTable dark={true}>
      <FooterCell1 />
      <FooterCell4 />
      <FooterCell3 />
      <Copy />
    </FooterTable>
  </FooterWraper>
);
