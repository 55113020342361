import React from "react";
import styled from "styled-components";
import { MapU } from "./Map";
import obrazekTBP from "../../images/backgrounds/cranes3.jpg";

export const KontaktWB = () => (
  <Kontakt>
    <Obrazek src={obrazekTBP} />
    <>
      <Osoba>
        <h2>Oddział Szczecin - Realizacja Inwestycji Budowlanych</h2>
        <h4>ul. Poznańska 1A/25, 71-785 Szczecin</h4>
        <h4>
          tel/fax: &nbsp;<a href="tel:+48 91 421 39 42">91 421 39 42</a>
        </h4>
        <h4>
          e-mail: &nbsp;
          <a href="mailto:biuro@zielinski.info.pl">szczecin@zielinski.info.pl</a>
        </h4>
      </Osoba>
      <Osoba>
        <h2>Właściciel Firmy - Rafał Zieliński</h2>
        <h4>
          tel: &nbsp;<a href="tel:+48 693 432 595">693 432 595</a>
        </h4>
        <h4>
          e-mail: &nbsp;
          <a href="mailto:biuro@zielinski.info.pl">biuro@zielinski.info.pl</a>
        </h4>
      </Osoba>
      <Osoba>
        <h2>Dyrektor Techniczny - Jarosław Gryglewicz</h2>
        <h4>
          tel: &nbsp;<a href="tel:+48 501 120 925">501 120 925</a>
        </h4>
        <h4>
          e-mail: &nbsp;
          <a href="mailto:jarekgryglewicz@zielinski.info.pl">
            jarekgryglewicz@zielinski.info.pl
          </a>
        </h4>
      </Osoba>
      <Osoba>
        <h2>Kierownik Biura - Waldemar Kucharski</h2>
        <h4>
          tel: &nbsp;<a href="tel:+48 882 851 411">882 851 411</a>,&nbsp;
          <a href="tel:+48 91 421 39 42">91 421 39 42</a>
        </h4>
        <h4>
          e-mail: &nbsp;
          <a href="mailto:szczecin@zielinski.info.pl">
            szczecin@zielinski.info.pl
          </a>
        </h4>
        <h4>
          fax: &nbsp;<a href="tel:+48 91 421 39 42">91 421 39 42</a>
        </h4>
      </Osoba>
      <Osoba>
        <h2>Główna Księgowa - Mirosława Sobiechowska</h2>
        <h4>
          tel: &nbsp;<a href="tel:+48 661 401 302">661 401 302</a>
        </h4>
        <h4>
          e-mail: &nbsp;
          <a href="mailto:ksiegowa@zielinski.info.pl">
            ksiegowa@zielinski.info.pl
          </a>
        </h4>
      </Osoba>
    </>
    <MapU />
    <MapLink>
      <a href="https://www.openstreetmap.org/#map=15/53.4654/14.5429&amp;layers=N">
        <h4>Wyświetl większą mapę</h4>
      </a>
    </MapLink>
  </Kontakt>
);

const MapLink = styled.div`
  padding-left: 60px;
  text-align: center;
  h4,
  a {
    color: rgb(0, 54, 67);
    opacity: 0.95;
    text-decoration: none;
  }
  background: #e6ebeb;
  z-index: 3;
  box-shadow: 0px 1px 1px rgba(19, 17, 17, 0.4) inset;
`;

const Kontakt = styled.table`
  height: auto;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  opacity: 0.9;
  justify-content: space-between;
  z-index: 0;
  padding-top: 90px;
  @media (max-width: 900px) {
    padding-top: 260px;
  }
`;

const Osoba = styled.div`
  padding-top: 25px;
  padding-left: 180px;
  a {
    color: #003643ff;
    opacity: 0.95;
  }
  h2 {
    color: rgb(0, 54, 67);
    opacity: 0.95;
  }
  h4 {
    color: rgb(0, 54, 67);
    opacity: 0.95;
  }
  @media (max-width: 900px) {
    padding-left: 20;
    padding-right: 20;
    text-algin: center;
  }
`;

const Obrazek = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  opacity: 60%;
  top: 0px;
  
`;
