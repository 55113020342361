import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import obrazekTBP from "../../images/backgrounds/cranes1.jpg";
import { Realizacje } from "./Realizacje.js";
import realizacje from "../../images/realizacje/*/*.jpg";
console.log(Object.entries(realizacje));

export const Galeria = ({ match }) => (
  <Wraper>
    <Obrazek src={obrazekTBP} />
    {Object.entries(realizacje)
      .filter(([directory, files]) => directory === match.params.directory)
      .slice(0, 1)
      .map(([directory, files]) => (
        <Gallery>
          <h2>{directory}</h2>
          {Object.entries(files).map(([name, url]) => (
            <img src={url} alt={name} title={name} style={{ padding: 15 }} />
          ))}
        </Gallery>
      ))}
    <BackLink to="/wykonawstwo_budowlane/realizacje/">
      <h2>WRÓĆ</h2>
    </BackLink>
  </Wraper>
);

const BackLink = styled(Link)`
  text-decoration: none;

  h2 {
    position: relative;
    width: auto;
    text-align: center;
    opacity: 0.95;
    color: rgb(0, 54, 67);
    box-shadow: 0px 1px 1px rgba(19, 17, 17, 0.4) inset;
    padding: 15px;
  }
`;

const Obrazek = styled.img`
  width: 100%;
  height: 20vh;
  position: relative;
  object-fit: cover;
  opacity: 60%;
  top: 0px;
  @media (max-width: 900px) {
    height: 330px;
  }
`;

const Wraper = styled.div`
  width: 100%;
  position: relative;
`;

const Gallery = styled.div`
  display: grid;
  object-fit: cover;
  position: relative;
  top: 0px;
  margin-top: 0px;
  align-content: center;
  img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    position: relative;
    object-fit: cover;
    opacity: 100%;
    box-shadow: 0px 0px 2px rgba(19, 17, 17, 0.4);
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h2 {
    position: relative;
    width: auto;
    text-align: center;
    opacity: 0.95;
    color: rgb(0, 54, 67);
    margin: 40px;
    text-transform: uppercase;
  }
`;
