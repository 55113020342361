import React from "react";
import styled from "styled-components";
import Iframe from "react-iframe";
import { Route, Switch, Redirect } from "react-router-dom";

const Bp = () => (
  <MapContainer>
    <MapFrame
      src="https://www.openstreetmap.org/export/embed.html?bbox=14.507017135620119%2C53.45366254705114%2C14.578685760498049%2C53.477192103438554&amp;layer=mapnik&amp;marker=53.458030615862285%2C14.541617631912231"
      scrolling="no"
    />
  </MapContainer>
);

const Wb = () => (
  <MapContainer>
    <MapFrame
      src="https://www.openstreetmap.org/export/embed.html?bbox=14.507017135620119%2C53.45366254705114%2C14.578685760498049%2C53.477192103438554&amp;layer=mapnik&amp;marker=53.4725546066618%2C14.543849229812622"
      scrolling="no"
    />
  </MapContainer>
);

export const MapU = () => (
  <Switch>
    <Route path="/wykonawstwo_budowlane" component={Wb} />
    <Route path="/biuro_projektow" component={Bp} />
  </Switch>
);

const MapFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const MapContainer = styled.div`
  position: relative;
  align-content: center;
  width: 100%;
  height: 320px;
  @media (max-width: 900px) {
    flex-direction: column;
    height: 160px;
  }
  }
`;
