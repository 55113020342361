import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import logoBP from "/images/logoBP.svg";
import logoWB from "/images/logoWB.svg";

export const Top_ContentWB = () => (
  <Menu>
    <MenuLink to="/" exact>
      <Logo src={logoWB} />
    </MenuLink>
    <MenuLink to="/wykonawstwo_budowlane" exact>
      <h2>O firmie</h2>
    </MenuLink>
    <MenuLink to="/wykonawstwo_budowlane/realizacje">
      <h2>Realizacje</h2>
    </MenuLink>
    <MenuLink to="/biuro_projektow">
      <h2>Pracownia Projektowa</h2>
    </MenuLink>
    <MenuLink to="/wykonawstwo_budowlane/kontakt" exact>
      <h2>Kontakt</h2>
    </MenuLink>
  </Menu>
);

const Logo = styled.img`
  top: 0px;
  margin-bottom: 5px;
  margin-top: 5px;
  height: 80px;
  margin-left: 180px;
  margin-right: 80px;
  position: relative;
  @media (max-width: 900px) {
    align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    margin-top: 25px;
  }
`;

const Menu = styled.table`
  position: relative;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 1px 1px rgba(19, 17, 17, 0.4);
  background: #003643ff;
  opacity: 0.9;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const MenuLink = styled(NavLink)`
  color: #e6ebeb;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  display: flex;
  min-width: 60px;

  @media (max-width: 900px) {
    align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  h2 {
    width: auto;
    margin: auto;
    padding-right: 15px;
    padding-left: 15px;
    text-align: center;
    @media (max-width: 1200px) {
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  &.active {
    color: #c9d029;
    border-style: dotted;
    border-width: 2px;
    border-color: #002933;
    background-image: linear-gradient(to bottom, #003643ff, #002f3b, #002933);
  }

  :focus-within {
    color: #c9d029;
    border-style: dotted;
    border-width: 2px;
    border-color: #002933;
    background-image: linear-gradient(to bottom, #003643ff, #002f3b, #002933);
  }
`;
